import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';
export default function AuthGuard({ children }) {
    const { isAuthenticated, isInitialized, user } = useAuthContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [requestedLocation, setRequestedLocation] = useState(null);
    useEffect(() => {
        if (user?.changedPassword === true && pathname === '/update-password') {
            navigate('/dashboard/app', { replace: true });
        }
    }, [user?.changedPassword, pathname]);
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    return _jsx(_Fragment, { children: children });
}
